import React from "react";
import HeroSection from "../components/HeroSection";
import SpecialDishSection from "../components/SpecialDishSection";
import TestimonialsSection from "../components/TestimonialsSection";
import ServiceSection from "../components/ServiceSection";
import { Helmet } from "react-helmet";

export const LandingPage = () => {
  return (
    <>
      <Helmet>
        <title>Ambrosia - Modern Indian Restaurant in South Melbourne</title>
        <meta
          name="description"
          content="Explore a blend of traditional and modern Indian cuisine at Ambrosia - The Culinary Exchange. Located in South Melbourne, our restaurant offers a unique dining experience with fusion dishes, fine dining, vegetarian options, and more. Reserve your table today!"
        />
      </Helmet>
      <div style={{ marginTop: "-150px" }}>
        <HeroSection />
        <ServiceSection />
        <SpecialDishSection />
        <TestimonialsSection />
      </div>
    </>
  );
};

export default LandingPage;
