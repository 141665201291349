import React from "react";
import "./LocationSection.css"; // Importing the corresponding CSS

const LocationSection = () => (
  <section aria-label="location" className="section features text-center" id="location">
    <div className="container">
      <p className="section-subtitle label-2">Where to Find Us</p>
      <h2 className="headline-1 section-title">LOCATION</h2>
      <div
        style={{
          overflow: "hidden",
          maxWidth: "100%",
          width: "80%",
          height: "500px",
          margin: "auto",
        }}
      >
        <div id="embed-map-canvas" style={{ height: "100%", width: "100%", maxWidth: "100%" }}>
          <iframe
            title="Ambrosia"
            frameBorder="0"
            src="https://www.google.com/maps/embed/v1/place?q=Ambrosia+-+The+Culinary+Exchange,+Park+Street,+South+Melbourne+VIC,+Australia&amp;key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
            style={{
              height: "100%",
              width: "100%",
              border: "0",
              filter: "invert(90%)",
            }}
          ></iframe>
        </div>
      </div>
      <img
        alt="shape"
        className="shape shape-1"
        height="178"
        loading="lazy"
        src="/assets/images/shape-7.png"
        width="208"
      />
      <img
        alt="shape"
        className="shape shape-2"
        height="115"
        loading="lazy"
        src="/assets/images/shape-8.png"
        width="120"
      />
    </div>
  </section>
);

export default LocationSection;
