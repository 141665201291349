import React from "react";
import "./ServiceSection.css"; // Importing the corresponding CSS

const getMenuLink = () => {
  return window.location.pathname === "/menu/" ? ["#food", "#drinks"] : ["/menu/#food", "/menu/#drinks"];
};

const ServiceSection = () => (
  <section aria-label="service" className="section service bg-black-10 text-center">
    <div className="container">
      <p className="section-subtitle label-2">Flavors For Royalty</p>
      <h2 className="headline-1 section-title">We Offer Top Notch</h2>
      <p className="section-text">
        Each dish at Ambrosia is crafted with the finest ingredients, replicating the flavours that once delighted the
        royal palates of India. Our menu is a gastronomic journey through the Indian subcontinent, with each region's
        unique flavours brought to life in our chef's special fusion dishes.
      </p>
      <ul className="grid-list">
        <li>
          <div className="service-card">
            <a className="has-before hover:shine" href={getMenuLink()[0]}>
              <figure className="card-banner img-holder" style={{ "--width": 285, "--height": 336 }}>
                <img
                  alt="Breakfast"
                  className="img-cover"
                  height="336"
                  loading="lazy"
                  src="/assets/images/service-1.jpg"
                  width="285"
                />
              </figure>
            </a>
            <div className="card-content">
              <h3 className="title-4 card-title">
                <a href={getMenuLink()[0]}>Lunch</a>
              </h3>
              <a className="btn-text hover-underline label-2" href={getMenuLink()[0]}>
                View Menu
              </a>
            </div>
          </div>
        </li>
        <li>
          <div className="service-card">
            <a className="has-before hover:shine" href={getMenuLink()[0]}>
              <figure className="card-banner img-holder" style={{ "--width": 285, "--height": 336 }}>
                <img
                  alt="Appetizers"
                  className="img-cover"
                  height="336"
                  loading="lazy"
                  src="/assets/images/service-2.jpg"
                  width="285"
                />
              </figure>
            </a>
            <div className="card-content">
              <h3 className="title-4 card-title">
                <a href={getMenuLink()[0]}>Dinner</a>
              </h3>
              <a className="btn-text hover-underline label-2" href={getMenuLink()[0]}>
                View Menu
              </a>
            </div>
          </div>
        </li>
        <li>
          <div className="service-card">
            <a className="has-before hover:shine" href={getMenuLink()[1]}>
              <figure className="card-banner img-holder" style={{ "--width": 285, "--height": 336 }}>
                <img
                  alt="Drinks"
                  className="img-cover"
                  height="336"
                  loading="lazy"
                  src="/assets/images/service-3.jpg"
                  width="285"
                />
              </figure>
            </a>
            <div className="card-content">
              <h3 className="title-4 card-title">
                <a href={getMenuLink()[1]}>Drinks</a>
              </h3>
              <a className="btn-text hover-underline label-2" href={getMenuLink()[1]}>
                View Menu
              </a>
            </div>
          </div>
        </li>
      </ul>
      <img
        alt="shape"
        className="shape shape-1 move-anim"
        height="412"
        loading="lazy"
        src="/assets/images/shape-1.png"
        width="246"
      />
      <img
        alt="shape"
        className="shape shape-2 move-anim"
        height="345"
        loading="lazy"
        src="/assets/images/shape-2.png"
        width="343"
      />
    </div>
  </section>
);

export default ServiceSection;
