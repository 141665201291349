// SpecialEvent.jsx
import React from "react";
import { Link } from "react-router-dom";
import "./SpecialEvent.css";

const SpecialEvent = ({ title, description, menu, pricing, bookingLink }) => {
  return (
    <div className="special-event-page">
      <div className="event-hero">
        <img src="/assets/images/valentines-day.png" alt="Valentine's Day Dinner" className="hero-image" />
        <div className="hero-content">
          <h1 className="headline-1">{title}</h1>
          <p className="body-2" style={{ paddingTop: "10px" }}>
            {description}
          </p>
          <div className="pricing-section">
            <p className="body-1">{pricing}</p>
            <div className="center-container" style={{ marginTop: "20px" }}>
              <Link className="btn btn-primary pulse" to={bookingLink}>
                Reserve a Table
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* Menu sections */}
      {menu}

      {/* Footer section */}
      <div className="event-footer">
        <div className="pricing-section">
          <p className="body-1">{pricing}</p>
          <div className="center-container" style={{ marginTop: "20px" }}>
            <Link className="btn btn-primary pulse" to={bookingLink}>
              Book Now
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpecialEvent;
