import React from "react";
import { BsChevronUp } from "react-icons/bs";
import { FaRegCalendarAlt } from "react-icons/fa";

import "./Footer.css"; // Importing the corresponding CSS
import { Link } from "react-router-dom";

const Footer = () => (
  <>
    <footer
      className="footer section has-bg-image text-center"
      style={{ backgroundImage: "url('./assets/images/footer-bg.jpg')" }}
    >
      <div className="container">
        <div className="footer-top grid-list">
          <div className="footer-brand has-before has-after">
            <a className="logo" href="/">
              <img alt="grilli home" height="50" loading="lazy" src="/assets/images/mandala.svg" width="160" />
            </a>
            <Link to="https://goo.gl/maps/BKATnVWMYbDJ3oDX7" target="_blank" rel="noreferrer">
              <address className="body-4">274 Park Street, South Melbourne, VIC 3205</address>
            </Link>
            <Link className="body-4 contact-link" to="mailto:msvfoodsau@gmail.com">
              msvfoodsau@gmail.com
            </Link>
            <Link className="body-4 contact-link" to="tel:+61480246220">
              Booking Request : +61480246220
            </Link>
            <p className="body-4">Dinner: 6pm to 11pm</p>
            <p className="body-4">Lunch (Sat-Sun): 11am - 3pm</p>
            <div className="wrapper">
              <div className="separator"></div>
              <div className="separator"></div>
              <div className="separator"></div>
            </div>
            <p className="title-1">Get News &amp; Offers</p>
            <p className="label-1">
              Subscribe us &amp; Get <span className="span">10% Off.</span>
            </p>
            <form action="" className="input-wrapper">
              <div className="icon-wrapper">
                <ion-icon aria-hidden="true" name="mail-outline"></ion-icon>
                <input
                  autoComplete="off"
                  className="input-field"
                  name="email_address"
                  placeholder="Your email"
                  type="email"
                />
              </div>
              <button className="btn btn-secondary" type="submit">
                <span className="text text-1">Subscribe</span>
                <span aria-hidden="true" className="text text-2">
                  Subscribe
                </span>
              </button>
            </form>
          </div>
          <ul className="footer-list">
            <li>
              <Link className="label-2 footer-link hover-underline" to="/home">
                Home
              </Link>
            </li>
            <li>
              <Link className="label-2 footer-link hover-underline" to="/menu">
                Menus
              </Link>
            </li>
            <li>
              <Link className="label-2 footer-link hover-underline" to="/gallery">
                Gallery
              </Link>
            </li>
            <li>
              <Link className="label-2 footer-link hover-underline" to="/about">
                Our Story
              </Link>
            </li>
            <li>
              <Link className="label-2 footer-link hover-underline" to="/location">
                Find Us
              </Link>
            </li>
            <li>
              <Link className="label-2 footer-link hover-underline" to="/contact">
                Contact
              </Link>
            </li>
          </ul>
          <ul className="footer-list">
            <li>
              <Link className="label-2 footer-link hover-underline" to="https://www.facebook.com/ambrosia.tce">
                Facebook
              </Link>
            </li>
            <li>
              <Link className="label-2 footer-link hover-underline" to="https://www.instagram.com/ambrosia.tce/">
                Instagram
              </Link>
            </li>
            <li>
              <Link className="label-2 footer-link hover-underline" to="/">
                Twitter
              </Link>
            </li>
            <li>
              <Link className="label-2 footer-link hover-underline" to="https://goo.gl/maps/BKATnVWMYbDJ3oDX7">
                Google Map
              </Link>
            </li>
          </ul>
        </div>
        <div className="footer-bottom">
          <p className="copyright">
            © 2024 MSV FOODS PTY. LTD. All Rights Reserved | Crafted by{" "}
            <Link className="link" href="https://tokom.au" target="_blank" rel="noreferrer">
              tokom
            </Link>
          </p>
        </div>
      </div>
    </footer>
    <a href="/reservation" className="book-table-btn active" aria-label="book a table">
      <FaRegCalendarAlt color="#000" />
    </a>
    <a href="#top" className="back-top-btn active" aria-label="back to top" data-back-top-btn="">
      <BsChevronUp />
    </a>
  </>
);

export default Footer;
