import React from "react";
import "./AboutSection.css"; // Importing the corresponding CSS
import { Link } from "react-router-dom";

const AboutSection = () => (
  <section aria-labelledby="about-label" className="section about text-center" id="about">
    <div className="container">
      <div className="about-content">
        <p className="label-2 section-subtitle" id="about-label">
          Our Story
        </p>
        <h2 className="headline-1 section-title">Every Flavour Tells a Story</h2>
        <p className="section-text">
          Step into Ambrosia in South Melbourne, an extraordinary Indian fusion restaurant where culinary artistry knows
          no bounds. We craft an enchanting dining journey by blending the bold, vibrant flavors of India with the
          global palate, delivering comfort with a touch of novelty. Our chef's special fusion dishes embody our
          commitment to innovation, inviting you to savor a symphony of tastes that celebrate both tradition and
          creativity.
        </p>
        <div className="contact-label">Book Through Call</div>
        <Link className="body-1 contact-number hover-underline" to="tel:+61480246220">
          +61480246220
        </Link>
        <Link
          className="btn btn-primary"
          target="_blank"
          rel="noreferrer"
          to="https://www.opentable.com.au/r/ambrosia-the-culinary-exchange-reservations-south-melbourne?restref=211277&lang=en-AU&ot_source=Restaurant%20website"
        >
          <span className="text text-1">Book Now</span>
          <span aria-hidden="true" className="text text-2">
            Book Now
          </span>
        </Link>
      </div>
      <figure className="about-banner">
        <img
          alt="Indian Restaurant in South Melbourne"
          className="w-100"
          parallax-item=""
          parallax-speed="1"
          height="570"
          loading="lazy"
          src="/assets/images/about-banner.jpg"
          width="570"
        />
        <div className="abs-img abs-img-1 has-before" parallax-item="" parallax-speed="1.75">
          <img
            alt=""
            className="w-100"
            height="285"
            loading="lazy"
            src="/assets/images/about-abs-image.jpg"
            width="285"
          />
        </div>
        <div className="abs-img abs-img-2 has-before">
          <img
            alt=""
            height="124"
            loading="lazy"
            src="/assets/images/mandala.svg"
            width="123"
            style={{ transform: "rotate(180deg)", padding: "15px" }}
          />
        </div>
      </figure>
      <img alt="" className="shape" height="194" loading="lazy" src="/assets/images/shape-3.png" width="197" />
    </div>
  </section>
);

export default AboutSection;
